import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";

import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import { useForm } from "../../shared/hooks/form-hook";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_PASSWORDMATCH,
} from "../../shared/utils/validators";
import SocialBtn from "../components/SocialBtn";

import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "./auth.css";
import ErrorDiv from "../../shared/components/UIElements/ErrorDiv";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { NavLink, useParams, useLocation, Redirect } from "react-router-dom";
import ModalRefer from "../../shared/components/UIElements/ModalRefer";
import { useConfig } from "../../shared/hooks/config-hook";
import fpPromise from "../../finger_print";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Auth() {
  const auth = useContext(AuthContext);
  const [getConfig] = useConfig();
  const history = useHistory();
  const [isLoginMode, setLoginMode] = useState(true);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [showModal, setShowModal] = useState(false);
  const [refer, setReferCode] = useState();
  const [loading, setLoading] = useState(false);

  const ouathParam = useParams().token1;
  const ouathToken = useQuery().get("code");

  const referC = useQuery().get("refer");

  const EmailTokenVeify = useParams().token;

  function onInputHandler(event) {
    setReferCode(event.target.value);
  }

  useEffect(() => {
    document.querySelector("html").style.fontSize = "10px";
    if (referC) {
      setLoginMode(false);
      localStorage.setItem("referCode", referC);
      setReferCode(referC);
    }

    async function verifyToken() {
      try {
        console.log(referC);

        if (ouathToken) {
          let ResponseData;
          const socialEndpoint =
            ouathParam === "callback"
              ? `/users/auth/facebook/callback?code=${ouathToken}`
              : `/users/auth/google/secrets?code=${ouathToken}`;

          setLoading(true);
          if (!localStorage.getItem("fingerData")) {
            const fingerData = await (
              await fpPromise
            ).get({ extendedResult: true });
            localStorage.setItem("fingerData", JSON.stringify(fingerData));
          }
          setLoading(false);
          ResponseData = await sendRequest(
            socialEndpoint,
            "POST",
            JSON.stringify({
              referCode: isNaN(localStorage.getItem("referCode"))
                ? localStorage.getItem("referCode")
                : localStorage.getItem("referCode")?.toString(),
              androidId: localStorage.getItem("androidId"),
              userInfo: JSON.parse(localStorage.getItem("fingerData")),
            }),
            {
              "Content-Type": "application/json"
            }
          );
          auth.login(ResponseData.userId, ResponseData.token);
        }
      } catch (err) {}
    }
    verifyToken();
  }, [sendRequest, ouathToken, auth, ouathParam, referC, refer]);

  useEffect(() => {
    if (EmailTokenVeify) {
      sendRequest(`/users/auth/verify/${EmailTokenVeify}`)
        .then(() => {
          history.push("/");
        })
        .catch(() => {
          history.push("/");
        });
    }
  }, [EmailTokenVeify, history, sendRequest]);

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  function switchModeHandler() {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          passwordConfirm: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          passwordConfirm: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setLoginMode((prevMode) => !prevMode);
  }

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    if (isLoginMode) {
      try {
        const responseData = await sendRequest(
          "/users/login",
          "POST",
          JSON.stringify({
            username: formState.inputs.email.value,
            password: formState.inputs.password.value,
            androidId: localStorage.getItem("androidId"),
          }),
          {
            "Content-Type": "application/json",
          }
        );
        auth.login(responseData.userId, responseData.token);
      } catch (err) {}
    } else {
      try {
        setLoading(true);
        if (!localStorage.getItem("fingerData")) {
          const fingerData = await (
            await fpPromise
          ).get({ extendedResult: true });
          localStorage.setItem("fingerData", JSON.stringify(fingerData));
        }
        setLoading(false);
        await sendRequest(
          "/users/register",
          "POST",
          JSON.stringify({
            username: formState.inputs.email.value,
            password: formState.inputs.password.value,
            confirmPassword: formState.inputs.passwordConfirm.value,
            referCode: isNaN(refer) ? refer : refer.toString(),
            androidId: localStorage.getItem("androidId"),
            userInfo: JSON.parse(localStorage.getItem("fingerData")),
          }),
          {
            "Content-Type": "application/json",
          }
        );
      } catch (err) {}
    }
  };

  const authSubmitHandler1 = async (event) => {
    event.preventDefault();
    if (!isLoginMode) {
      setLoading(true);
      if (!localStorage.getItem("fingerData")) {
        const fingerData = await (
          await fpPromise
        ).get({ extendedResult: true });
        localStorage.setItem("fingerData", JSON.stringify(fingerData));
      }
      setLoading(false);
      try {
        await sendRequest(
          "/users/registerWithOutReferCode",
          "POST",
          JSON.stringify({
            username: formState.inputs.email.value,
            password: formState.inputs.password.value,
            confirmPassword: formState.inputs.passwordConfirm.value,
            androidId: localStorage.getItem("androidId"),
            userInfo: JSON.parse(localStorage.getItem("fingerData")),
          }),
          {
            "Content-Type": "application/json",
          }
        );
      } catch (err) {}
    }
  };

  function showReferModal(e) {
    e.preventDefault();
    setShowModal(true);
  }
  function closeModalHandler() {
    setShowModal(false);
  }

  return (
    <div id="wrapper">
      <ModalRefer
        show={showModal}
        onCancel={closeModalHandler}
        title="INVITATION CODE"
        points="25"
        description="Do you have Invitation code? If yes Input code and get Bonus."
        onInput={onInputHandler}
        referCode={refer}
        onSubmit={authSubmitHandler}
        onSubmitWithNoCode={authSubmitHandler1}
      />
      <div className="container">
        {(isLoading || loading) && <LoadingSpinner asOverlay />}
        <div className="phone-app-demo"></div>
        <div className="form-data">
          <form onSubmit={isLoginMode ? authSubmitHandler : showReferModal}>
            <div className="logo">
              <h1>{getConfig("identity", "appName")}</h1>
            </div>
            {error && <ErrorDiv>{error}</ErrorDiv>}
            {error && !isLoading && auth.isLoggedIn && (
              <Redirect to="/profile" />
            )}
            <Input
              element="input"
              id="email"
              type="text"
              placeholder="Email"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email"
              onInput={inputHandler}
            />
            <Input
              element="input"
              id="password"
              type="password"
              placeholder="Password"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid Password (at last 6 characters)"
              onInput={inputHandler}
            />
            {!isLoginMode && (
              <Input
                element="input"
                id="passwordConfirm"
                type="password"
                placeholder="Confirm Password"
                validators={[
                  VALIDATOR_PASSWORDMATCH(formState.inputs.password.value),
                ]}
                errorText="Password mismatch"
                onInput={inputHandler}
              />
            )}
            <Button
              className="form-btn"
              type="submit"
              disabled={!formState.isValid}
            >
              {isLoginMode ? "Log In" : "Sign Up"}
            </Button>
            <span className="has-separator">Or</span>
            <SocialBtn
              fb={`${getConfig("identity", "endpoint")}/users/auth/facebook`}
              google={`${getConfig("identity", "endpoint")}/users/auth/google`}
              androidId={localStorage.getItem("androidId")}
            />
            {isLoginMode && (
              <NavLink to="/reset">
                <Button className="password-reset" id="signup-btn">
                  Forgot password?
                </Button>
              </NavLink>
            )}
          </form>
          <div className="sign-up">
            {isLoginMode ? "Don't have an account?" : "Have an account?"}

            <Button
              disabled={loading || isLoading}
              id="signup-btn"
              onClick={switchModeHandler}
            >
              {!isLoginMode ? "Log In" : "Sign Up"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;

import React from "react";
import { Link } from "react-router-dom";

import './Button.css';

function Button(props) {
  if (props.href) {
    return (
      <a target="_blank" rel="noopener noreferrer" className={props.className} style={props.style} href={props.href} id={props.id} onClick={props.onClick}>
        {props.children} 
      </a>
    );
  }
  if (props.openAndroid) {
    return (
      <div className={props.className} style={props.style} id={props.id} onClick={props.openAndroid}>
        {props.children} 
      </div>
    );
  }  else if (props.to) {
    return (
      <Link
        className={props.className}
        onClick={props.onClick}
        style={props.style}
        to={props.to}
        id={props.id}
        exact={props.exact}
      >
        {props.children}
      </Link>
    );
  }
  return (
    <button
      className={`button ${props.className}`}
      id={props.id}
      style={props.style}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export default Button;

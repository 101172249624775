import React from "react";
import "./LoadingAndroid.css";
import btn from "./Google-Play-button.png"
import gift from "./gift.png"

function LoadingAndroid() {
  return (
    <div className="android-app">
      <div className="android-title">The Android App is now available on Google Play</div>
      <div className="android-subtitle">
        The app that lets you earn gift cards while playing games
      </div>
      <div className="android-img">
        <img alt="logo" src={gift}/>
      </div>

      <a target="_blank" rel="noopener noreferrer" href="https://v6iq5.app.link/test">
        <img className="android-btn" src={btn} alt="btn"/>
      </a>
    </div>
  );
}

export default LoadingAndroid;

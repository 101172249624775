import React from 'react';

import './LoadingSpinner.css';

const LoadingSpinner = props => {
  return (
    <div id={props.id} className={`${props.asOverlay && 'loading-spinner__overlay'} ${props.center && 'center'}`}>
      <div id={`${props.points && 'loading--points'}`} className="lds-dual-ring"></div>
    </div>
  );
};

export default LoadingSpinner;

import React,{useReducer, useEffect} from "react";
import { validate } from "../../utils/validators";
import "./Input.css";

function inputReducer(state,action) {
    switch(action.type) {
        case 'CHANGE' : {
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val,action.validators)
            };
        }
        case 'TOUCH' : {
            return {
                ...state,
                isTouched: true
            };
        }
        default:
            return state;
    }
}

function Input(props) {
    
    const [inputState,dispatch] = useReducer(inputReducer,{
        value:props.initialValue || '',
        isTouched: false,
        isValid: props.initialValid || false
    });
    const { id, onInput} = props;
    const { value, isValid} = inputState;


    useEffect(() =>{
        onInput(id,value,isValid)
    },[id,value,isValid,onInput])

    function changeHandler(event) {
        dispatch({
            type: 'CHANGE',
            val: event.target.value,
            validators: props.validators
        });
    }

    function touchHandler() {
        dispatch({
            type:'TOUCH',

        });
    }


  const element =
    props.element === "input" ? (
      <input className={`input-form ${props.className}`}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
      />
    ) : (
      <textarea></textarea>
    );

  return (
    <div
      className={`form-control-auth ${
        !inputState.isValid && inputState.isTouched && "form-control--invalid"
      }`}>
    {element}
    {!inputState.isValid && inputState.isTouched  && <p className="invalid-p">{props.errorText}</p>}
    </div>
  );
}

export default Input;

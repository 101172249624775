import React from 'react';
import ReactDom from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Backdrop from './BackDrop';

import './Modal.css';

function ModalOverlay(props) {
    const content = (
        <div className="modal-dialog modal-dialog-centered">
        <div id={props.id} className={`modal1 ${props.className}`} style={props.style}>
            <header className={`modal-header ${props.headerClass}`}>
                {props.header}
            </header>
            <div className={`modal-body ${props.bodyClass}`}>
                {props.body}
            </div>
            <footer className={`modal-footer ${props.footerClass}`}>
                {props.footer}
            </footer>
        </div>
        </div>
    );
    return ReactDom.createPortal(content,document.getElementById('modal-hook'));
}

function Modal(props) {
    return (
        <React.Fragment>
            {props.show && <Backdrop onClick={props.onCancel} />}
            <CSSTransition
            in={props.show}
            mountOnEnter
            unmountOnExit
            timeout={50}
            classNames="modal1"
            >
            <ModalOverlay {...props} />
            </CSSTransition>
        </React.Fragment>
    );
}

export default Modal;
export function showOfferwallAdgate() {
    window.Android.owAdgate();
}

export function showOfferwallAdgem() {
    window.Android.owAdGem();
}

export function fbShareAndroid(url,href,quote,imageUrl) {
    window.Android.fbShare(url,href,quote,imageUrl);
}

export function twShareAndroid(url,quote) {
    window.Android.twShare(url,quote);
}

export function fbPageAndroid(pageUrl) {
    window.Android.fbPage(pageUrl);
}
export function openLinkAndroid(link) {
    window.Android.openLink(link);
}

export function openEmailAndroid(to,subject,body) {
    window.Android.openEmail(to,subject,body);
}
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import RedeemIcon from "@material-ui/icons/Redeem";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ShareIcon from "@material-ui/icons/Share";
import { Navbar } from "react-bootstrap";
import {Link as RouterLink } from "react-router-dom";

import "./NavigationBottom.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#f6f5f5",
    borderTop: "1px solid #d3e0ea",
  },
});

export default function NavigationBottom({pathname,setpathName}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  useEffect(function() {
  if(pathname === "/reward") {
    setValue(1);
  } else if (pathname=== "/profile") {
    setValue(2) }
    else if(pathname === "/invite") {
      setValue(3);
    }
    else if(pathname === "/home") {
      setValue(0);
    }
  },[pathname]);

  return (
    <Navbar fixed="bottom" className="navBar_bottom">
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
          <BottomNavigationAction onClick={()=>{
            setpathName("/home")
          }} label="Home" icon={<HomeIcon />} component={RouterLink} to="/home"/>
        <BottomNavigationAction onClick={()=>{
            setpathName("/reward")
          }} label="Rewards" icon={<RedeemIcon />} component={RouterLink} to="/reward" />
        
        <BottomNavigationAction onClick={()=>{
            setpathName("/profile")
          }} label="Profile" icon={<AccountCircleIcon />} component={RouterLink} to="/profile" />
        <BottomNavigationAction onClick={()=>{
            setpathName("/invite")
          }} label="Share" icon={<ShareIcon />} component={RouterLink} to="/invite" />
      </BottomNavigation>
    </Navbar>
  );
}

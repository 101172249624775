import React from 'react'
import './Privacy_terms.css'

function Terms() {
    return (
        <div>
            <h2 className="privacyH2">AppNabil Terms</h2>
<p className="privacyP1">By using AppNabil's service ("Service"), you agree with these Terms of Service ("The
    Agreement"). If you do not agree with any of these terms, you are prohibited from using or accessing the
    Service. The materials contained on this web site (appnabil.com) are protected by copyright, trademark,
    and other applicable intellectual property rights.. AppNabil has the right to change the TOS in any way
    and at any time at its sole discretion. It is your responsibility to review these TOS so you are aware
    of any changes or updates. The terms "you" and "your" refers to the user of Service. You agree not to
    use the Service for any other purpose, or to copy or distribute the content of the Service except as
    specifically allowed in this Agreement.</p>

<p className="privacyP1">1. The Company reserves the right discontinue the Service or to change the content of the Service in
    any way and at any time, with or without notice to you, without liability.</p>

<p className="privacyP1">2. Your use of the Service is conditioned upon your compliance with the TOS and any use of the
    Service in violation thereof may be regarded as an infringement to the Service. The Company reserves
    the right to terminate your access to the Service without notice if you violate The Agreement.</p>

<p className="privacyP1">3. You are at least 13 years old or older. You represent and warrant to The Company that: (i) you are
    an individual acting in your individual capacity (i.e., not a corporation) and you are of legal age
    to form a binding contract or have your parent's permission to do so, and you are at least 13 years
    or age or older; (ii) all registration information you submit is accurate and truthful; and (iii)
    you will maintain the accuracy of such information. You also certify that you are legally permitted
    to use and access the Services and take full responsibility for the selection and use of and access
    to the Services. This Agreement is void where prohibited by law, and the right to access the
    Services is revoked in such jurisdictions.</p>

<p className="privacyP1">4. Warranty<br></br>You warrant, represent and agree that you will not use the Services in a manner that
    (i) infringes or violates the intellectual property rights or proprietary rights, rights of
    publicity or privacy, or other rights of any third party; (ii) violates any applicable law, statute,
    ordinance or regulation; (iii) is harmful, fraudulent, deceptive, threatening, abusive, harassing,
    tortious, defamatory, vulgar, obscene, libelous, or otherwise objectionable; (iv) involves
    commercial activities and/or sales without AppNabil's prior written consent such as contests,
    sweepstakes, barter, advertising, or pyramid schemes; (v) impersonates any person or entity,
    including without limitation any employee or representative of AppNabil; or (vi) contains a virus,
    trojan horse, worm, time bomb, or other harmful computer code, file, or program. AppNabil reserves
    the right to remove any Content from the Services at any time, for any reason (including, but not
    limited to, upon receipt of claims or allegations from third parties or authorities relating to such
    Content or if AppNabil is concerned that you may have used or operated the Service other than as
    intended, or for any other reason at AppNabil’s sole discretion.)</p>
        </div>
    )
}

export default Terms

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Auth from "../../login/pages/auth";
import { useHttpClient } from "../hooks/http-hook";
import ErrorDiv from "./UIElements/ErrorDiv";
import LoadingSpinner from "./UIElements/LoadingSpinner";
import {  useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import { isAndroid } from "react-device-detect";
import LoadingAndroid from "./LoadingAndroid";
import PasswordResetPage from "../../login/pages/passwordResetPage";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function LoadingScreen({token}) {

  const { isLoading, error, sendRequest } = useHttpClient();
  const [doneFetching, setDoneFetching] = useState(false);
  const mountedRef = useRef(true);
  const auth = useContext(AuthContext);


  const androidId = useQuery().get("android_id");
  const push = useQuery().get("push");
  const bssid = useQuery().get("bssid");
  const gaid = useQuery().get("gaid");
  const locale = useQuery().get("locale");
  const resolution = useQuery().get("resolution");
  const device_name = useQuery().get("device_name");
  const system = useQuery().get("system");
  const country_code = useQuery().get("country_code");
  const carrier_country = useQuery().get("carrier_country");
  const osversion = useQuery().get("osversion");


  const passResetToken = useParams().tokenreset;


  const d2 =new Date(Date.now());
  if(JSON.parse(localStorage.getItem('userData')) && new Date(JSON.parse(localStorage.getItem('userData')).expiration) < d2) {
    localStorage.removeItem('userData');
  }
  useEffect( () => {
    if (androidId) {
      localStorage.setItem("push",push);
      localStorage.setItem("androidId",androidId);
      localStorage.setItem("device",JSON.stringify({
        bssid,
        gaid,
        locale,
        resolution,
        device_name: device_name ? device_name : system,
        country_code,
        carrier_country,
        osversion,
        androidId
      }));
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[androidId]);

  const fetchConfig = useCallback(async () => {
    let ResponseData = await sendRequest("", "GET", null, {}, true);
    
    if (!mountedRef.current) return null;
    localStorage.setItem("config", JSON.stringify(ResponseData));
    if(androidId) {
      try {
        const responseData = await sendRequest(
          `/users/auth/loginAndroid/${androidId}`,
          "POST",
          "",
          {
            "Content-Type": "application/json",
          }
        );
        auth.login(responseData.userId, responseData.token);
      } catch (err) {
      }
    }
    
    setDoneFetching(true);
    if(isAndroid && androidId) {
      window.Android.splashHide();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mountedRef]);

  useEffect(() => {
    JSON.parse(localStorage.getItem('userData'))==null && fetchConfig();
    return () => {
      mountedRef.current = false;
    };
  }, [fetchConfig]);

 

  return (
    <>
      {isLoading && <LoadingSpinner center  />}
      {error && <ErrorDiv>{error}</ErrorDiv>}
      {passResetToken && doneFetching && !isLoading && <PasswordResetPage passResetToken={passResetToken} />}
      {isAndroid && !androidId && doneFetching && !isLoading && !passResetToken ? <LoadingAndroid />:doneFetching && !isLoading && !passResetToken && <Auth />}
    </>
  );
}

export default LoadingScreen;

import React, {
  Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import "./App.css";
import AppHeader from "./home/components/AppHeader";
import Faq from "./shared/components/Faq";
import Footer from "./shared/components/footer/footer";
import LoadingScreen from "./shared/components/LoadingScreen";
import Privacy from "./shared/components/privacy";
import Terms from "./shared/components/Terms";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
const Auth = React.lazy(() => import("./login/pages/auth"));

const Home = React.lazy(() => import("./home/pages/home"));
const Invite = React.lazy(() => import("./invite/invite"));
const PasswordResetPage = React.lazy(() =>
  import("./login/pages/passwordResetPage")
);
const Profile = React.lazy(() => import("./profile/profile"));
const Reward = React.lazy(() => import("./reward/reward"));

function App() {
  const { token, login, logout, userId } = useAuth();
  const [pathName, setpathName] = useState(window.location.pathname);

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Home setpathName={setpathName} />
        </Route>
        <Route path="/ma">
          <Home setpathName={setpathName}/>
        </Route>
        <Route path="/home">
          <Home setpathName={setpathName}/>
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/invite">
          <Invite  />
        </Route>
        <Route path="/auth/verify/:token">
        <Auth />
        </Route>
        <Route path="/reward">
          <Reward />
        </Route>
        <Route path="/files/terms" exact>
          <Terms />
        </Route>
        <Route path="/files/policy" exact>
          <Privacy />
        </Route>
        <Route path="/files/faq" exact>
          <Faq />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/reset/:tokenreset">
        <LoadingScreen token={token} />
        </Route>
        <Route path="/auth/verify/:token">
          <LoadingScreen token={token} />
        </Route>
        <Route path="/reset">
          <PasswordResetPage />
        </Route>
        <Route path="/auth/facebook/:token1">
          <Auth />
        </Route>
        <Route path="/auth/google/:token1">
          <Auth />
        </Route>
        <Route path="/files/terms" exact>
          <Terms />
        </Route>
        <Route path="/files/policy" exact>
          <Privacy />
        </Route>
        <Route path="/files/faq" exact>
          <Faq />
        </Route>
        <Route path="/:ma" >
          <LoadingScreen token={token} />
        </Route>
        <Route path="/" exact>
          <LoadingScreen token={token} />
        </Route>
        
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
        <Router>
          {token && <AppHeader pathName={pathName} setpathName={setpathName} />}
          <main>
            <Suspense
              fallback={
                <div className="center">
                  <LoadingSpinner />
                </div>
              }
            >
            <React.Fragment>
            {routes}

            </React.Fragment>
            </Suspense>
          </main>
        </Router>
        <Footer />
    </AuthContext.Provider>
  );
}

export default App;

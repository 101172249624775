import React from "react";
import { isAndroid } from "react-device-detect";
import { openEmailAndroid, openLinkAndroid } from "../../../home/pages/HomeAndroid";

import "./footer.css";

function Footer() {
  return (
    <footer>
      <div className="container">
        <nav className="footer-nav">
          <ul>
            {isAndroid ? (
              <>
                <li>
                  <div
                    onClick={() => {
                      console.log(window.location.origin + "/files/policy")
                      openLinkAndroid(
                        window.location.origin + "/files/policy"
                      );
                    }}
                  >
                    Privacy
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      openLinkAndroid(
                        window.location.origin + "/files/terms"
                      );
                    }}
                    href="/files/terms"
                  >
                    Terms
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      openLinkAndroid(
                        window.location.origin + "/files/faq"
                      );
                    }}
                  >
                    FAQ
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      openEmailAndroid("support@appnabil.com","AppNabil Support","");
                    }}
                  >
                    Contact us
                  </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a href="/files/policy">Privacy</a>
                </li>
                <li>
                  <a href="/files/terms">Terms</a>
                </li>
                <li>
                  <a href="/files/faq">FAQ</a>
                </li>
                <li>
                  <a href="mailto:support@appnabil.com">Contact us</a>
                </li>
              </>
            )}
          </ul>
        </nav>
        <div className="copyright-notice">&copy; Copyright {new Date().getFullYear()}</div>
      </div>
    </footer>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { isAndroid } from "react-device-detect";
import fpPromise from "../../finger_print";
import ErrorDiv from "../../shared/components/UIElements/ErrorDiv";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "./SocialBtn.css";

function SocialBtn(props) {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "event",
      async function (e) {
        if (props.androidId) {
          setLoading(true);
          if (!localStorage.getItem("fingerData")) {
            const fingerData = await (
              await fpPromise
            ).get({ extendedResult: true });
            localStorage.setItem("fingerData", JSON.stringify(fingerData));
          }
          setLoading(false);
          const ResponseData = await sendRequest(
            `/users/auth/facebook/token?access_token=${e.detail}`,
            "POST",
            JSON.stringify({
              referCode: isNaN(localStorage.getItem("referCode"))
                ? localStorage.getItem("referCode")
                : localStorage.getItem("referCode")?.toString(),
              androidId: localStorage.getItem("androidId"),
              userInfo: JSON.parse(localStorage.getItem("fingerData")),
            }),
            {
              "Content-Type": "application/json",
            }
          );
          auth.login(ResponseData.userId, ResponseData.token);
        }
      },
      { once: true }
    );

    window.addEventListener(
      "google",
      async function (e) {
        if (props.androidId) {
          setLoading(true);
          if (!localStorage.getItem("fingerData")) {
            const fingerData = await (
              await fpPromise
            ).get({ extendedResult: true });
            localStorage.setItem("fingerData", JSON.stringify(fingerData));
          }
          setLoading(false);
          const ResponseData = await sendRequest(
            `/users/auth/google/token/${e.detail}`,
            "POST",
            JSON.stringify({
              referCode: isNaN(localStorage.getItem("referCode"))
                ? localStorage.getItem("referCode")
                : localStorage.getItem("referCode")?.toString(),
              androidId: localStorage.getItem("androidId"),
              userInfo: JSON.parse(localStorage.getItem("fingerData")),
            }),
            {
              "Content-Type": "application/json",
            }
          );
          auth.login(ResponseData.userId, ResponseData.token);
        }
      },
      { once: true }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fbLogin() {
    window.Android.fbLogin();
  }

  function googleLogin() {
    window.Android.gplusLogin();
  }

  return (
    <React.Fragment>
      {error && <ErrorDiv>{error}</ErrorDiv>}
      {(isLoading || loading) && <LoadingSpinner asOverlay />}
      {isAndroid ? (
        <>
          {" "}
          <button
            type="button"
            onClick={fbLogin}
            className="loginBtn loginBtn--facebook"
          >
            Login with Facebook
          </button>
          <button
            type="button"
            onClick={googleLogin}
            className="loginBtn loginBtn--google"
          >
            Login with Google
          </button>
        </>
      ) : (
        <>
          {" "}
          <a href={props.fb}>
            <button type="button" className="loginBtn loginBtn--facebook">
              Login with Facebook
            </button>
          </a>
          <a href={props.google}>
            <button type="button" className="loginBtn loginBtn--google">
              Login with Google
            </button>
          </a>
        </>
      )}
    </React.Fragment>
  );
}

export default SocialBtn;

import React from "react";
import { useConfig } from "../../shared/hooks/config-hook";
import {Link } from "react-router-dom";


import "./AppHeader.css";
import NavigationBottom from "./NavigationBottom";

function AppHeader({setpathName,pathName}) {
  const [getConfig]=useConfig();
 // const config = JSON.parse(localStorage.getItem('config'));
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light main-navigation">
      <Link onClick={()=>{
setpathName("/home");    }}  to="/home" className="logo navbar-brand app-title teamSelector">
        <img
          className="d-inline-block align-top logo--img"
          src={getConfig("identity","logo")}
          width="50"
          height="50"
          alt="logo"
        />
        <span className="app-title"> {getConfig("identity","appName")}</span>
      </Link>
    </nav>
    <NavigationBottom pathname={pathName} setpathName={setpathName} />
    </>
  );
}

export default AppHeader;

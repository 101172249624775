import { useCallback, useContext } from "react";
import { AuthContext } from "../context/auth-context";

export const useConfig = () => {
  const config = JSON.parse(localStorage.getItem("config"));
  const auth = useContext(AuthContext);

  const getConfig = useCallback((data, data1) => {
      try {
    
        if (config[data][data1] === undefined) {
            auth.logout();
        } else {
            return config[data][data1];
        }
      } catch (error) {
          auth.logout();
      }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [getConfig];
};

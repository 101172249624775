import React from "react";
import "./Privacy_terms.css";

function Privacy() {
  return (
    <div>
      <h3 className="privacyH2">AppNabil Privacy Policy</h3>
      <p className="privacyP1">
        appnabil.com ("AppNabil") is committed to maintain the privacy of your
        personal information when you visit our website, use our related
        services (“Services”) and/or download and use any promoted third party
        applications accessible for download from the Site (“Applications”).
        This Privacy Policy describes how we treat the information collected
        from you in connection with your use of our Site, Services and
        Applications. This Privacy Policy further describes how we use and
        disclose information collected in connection with providing such
        services to publishers of Applications. By using the Site or Services,
        you are consenting to the collection, use, and sharing of information
        collected from you as described below in this Privacy Policy.
      </p>
      <h4 className="privacyH3">Collection on Information</h4>
      <h5 className="privacyH4">1. Registration and Device Syncing</h5>
      <p className="privacyP1">
        You can register either using you email or Facebook. Depending on how
        you choose to register, we may collect and store your email address
        and/or Facebook access token. We also link your email address or
        Facebook access token to a unique identifier that we associate with your
        mobile device. When you register with us and sign in to our services,
        you are not anonymous to us.
      </p>
      <h5 className="privacyH4">2. Device-Specific Information</h5>
      <p className="privacyP1">
        When you interact with one of our Application, we may collect the
        following information, including, but not limited to: an unique
        identification number, platform type and version, hardware and processor
        type, screen resolution, session length, MAC address, country and IP
        address.
      </p>
      <h5 className="privacyH4">
        3. Information Obtained from Linked Social Networking Sites
      </h5>
      <p className="privacyP1">
        When you register using Facebook, you allow us to access certain
        information from your profile. We can access following information,
        including, but not limited to: your user name, your Facebook User ID
        number, your profile picture URL, your list of friends, your gender,
        your birthday or age, your physical location and the location of your
        device, your occupation and education, your interests and group
        affiliations, your posts and email addresses and other contact
        information that is associated with your profile.
      </p>
      <p className="privacyP1">
        AppNabil may store and combine information that we access about you
        through linked social networks with the unique identifier that we
        associate with your mobile device, as well as other information that we
        collect about you or your use of our products and services.
      </p>
      <h5 className="privacyH4">4. App Usage Information</h5>
      <p className="privacyP1">
        We may collect information about your use of an Application, including
        the date and number of times that you launched an particular
        Application, the pages that you viewed, and any third-party apps that
        you selected for installation. We also collect information about the
        session ID, user ID, total play time, and session length.
      </p>
      <h5 className="privacyH4">5. Third Party Tools</h5>
      <p className="privacyP1">
        AppNabil website and promoted Applications may also contain third-party
        tracking tools from our service providers, an example of which includes
        Google Analytics, Branch.io. Such third parties may use cookies, APIs,
        and SDKs in our services to enable them to collect and analyze User
        information on our behalf. While a third party may have access to your
        personal information in connection with the performance of services for
        AppNabil, it may not use such information for any purpose other than
        providing its services. <br></br>
        Google - https://policies.google.com/privacy 
        <br></br>
        Facebook: https://www.facebook.com/about/privacy/ 
        <br></br>
        Applovin: https://www.applovin.com/privacy/
        <br></br>
        Analytic companies: provide us with tools and technologies that allow us to better understand how users
        interact with our services and will help us improve them. Those partners
        also usually collect data via their own SDKs
        <br></br>
        Branch.io: https://branch.io/policies/#privacy
      </p>
      <h5 className="privacyH4">6. Anonymous Information</h5>
      <p className="privacyP1">
        We may create anonymous information records from personal information by
        excluding information (such as your name) that make the data personally
        identifiable to you. AppNabil reserves the right to use anonymous
        information for any purpose and disclose anonymous information to third
        parties in its sole discretion.
      </p>
      <p className="privacyP1">
        We may also disclose personally identifiable information to a successor
        acquirer or other related entity, in connection with a sale or transfer
        of some or all of our assets, or when we, in good faith, believe that
        the law requires us to do so.
      </p>
      <h4 className="privacyH3">How We Use Your Information</h4>
      <h5 className="privacyH4">1. To enhance AppNabil website and Service</h5>
      <p className="privacyP1">
        We may use the information that we collect about you, including
        anonymous information to analyze request and usage patterns so that we
        may enhance the content of AppNabil website and the Service.
      </p>
      <h5 className="privacyH4">2. To Contact You</h5>
      <p className="privacyP1">
        We may periodically send promotional materials or notifications related
        to Applications to the email address that you provide to us when
        registering. If you want to stop receiving promotional materials from
        us, you can email us at support@appnabil.com.
      </p>
      <h5 className="privacyH4">3. To Send you Money</h5>
      <p className="privacyP1">
        When you qualify for a money transfer we use the Paypal account
        associated with you account to send you money.
      </p>
      <h5 className="privacyH4">4. Information Sharing and Disclosure</h5>
      <p className="privacyP1">
        AppNabil does not rent, sell, or share personal information about you
        with other people or companies which are not affiliates, except when we
        have your permission, as described above, or under the following
        circumstances:
      </p>
      <h5 className="privacyH4">5. Affiliates</h5>
      <p className="privacyP1">
        We may share some or all of your personal information with our current
        and future parent company, any subsidiaries, or other companies under a
        common control, in which case we will require our affiliates to honor
        this Privacy Policy.
      </p>
      <h5 className="privacyH4">6. Developers</h5>
      <p className="privacyP1">
        We share app-specific information in aggregated form with Developers.
        Such aggregated information may include, for example, how many unique
        individuals are logged in to an Application.
      </p>
      <h4 className="privacyH3">Your Choices</h4>
      <h5 className="privacyH4">1. Opting Out of Marketing</h5>
      <p className="privacyP1">
        You can "opt-out" from promotion emails by following the unsubscribe
        instructions provided in the e-mail you receive or by contacting us at
        support@appnabil.com.
      </p>
      <h5 className="privacyH4">2. Access & Choice </h5>
      <p className="privacyP1">
        You may have a right, in accordance with applicable laws, to access,
        update, correct, and in some cases requests deletion of the personal
        information that we hold about you. When updating your personal
        information, we may ask you to verify your identity before we can act on
        your request. We will comply with your request as soon as reasonably
        practicable and in accordance with applicable laws. We may need to
        retain certain information for recordkeeping purposes, as required under
        applicable legal obligations, or to complete any transactions that you
        began prior to requesting such change or access.
      </p>
      <h4 className="privacyH3">Information Security</h4>
      <p className="privacyP1">
        We use reasonable efforts to protect your personal information against
        unauthorized access and unauthorized alteration, disclosure or
        destruction. However, we cannot guarantee that your personal information
        will be absolutely protected. For example, your personal information may
        be affected by actions outside of our control, such as computer
        "hacking" and physical theft. You acknowledge that you provide your
        personal information at your own risk.
      </p>
      <h4 className="privacyH3">Cookies</h4>
      <p className="privacyP1">
        Cookies are small bits of information sent by a host that is stored on
        your hard disk by your computer’s browser. Cookies speed up your online
        usage by remembering your personal information such as your User ID
        (your e-mail address and/or password), and gather your online activities
        data for marketing or promotional purposes.
      </p>
      <p className="privacyP1">
        You may disable cookies from the website on your browser. However, each
        browser has its unique cookies preference setting so please refer to
        your browser for more information.
      </p>
      <h4 className="privacyH3">Children under the Age of 13</h4>
      <p className="privacyP1">
        We do not intentionally collect any information from anyone under 13
        years of age. Our Site, products and services are all directed to people
        who are at least 13 years old or older.
      </p>
      <h4 className="privacyH3">Survey Serving Technology</h4>
      <p className="privacyP1">
        This app uses Pollfish SDK. Pollfish is an on-line survey platform,
        through which, anyone may conduct surveys. Pollfish collaborates with
        Developers of applications for smartphones in order to have access to
        users of such applications and address survey questionnaires to them.
        When a user connects to this app, a specific set of user’s device data
        (including Advertising ID which will may be processed by Pollfish only
        in strict compliance with google play policies- and/or other device
        data) and response meta-data (including information about the apps which
        the user has installed in his mobile phone) is automatically sent to
        Pollfish servers, in order for Pollfish to discern whether the user is
        eligible for a survey. For a full list of data received by Pollfish
        through this app, please read carefully Pollfish respondent terms
        located at https://www.pollfish.com/terms/respondent. These data will be
        associated with your answers to the questionnaires whenever Pollfish
        sents such questionnaires to eligible users. By downloading the
        application you accept this privacy policy document and you hereby give
        your consent for the processing by Pollfish of the aforementioned data.
        Furthermore, you are informed that you may disable Pollfish operation at
        any time by using the Pollfish “opt out section” available on Pollfish
        website . We once more invite you to check the respondent’s terms of
        use, if you wish to have more detailed view of the way Pollfish works.
      </p>
      <p className="privacyP1">
        APPLE, GOOGLE AND AMAZON ARE NOT A SPONSOR NOR ARE INVOLVED IN ANY WAY
        IN THIS CONTEST/DRAW. NO APPLE PRODUCTS ARE BEING USED AS PRIZES.
      </p>
      <h4 className="privacyH3">Contact Information</h4>
      <p className="privacyP1">
        If you have any questions about this Privacy Policy, you may contact us
        at: support@appnabil.com.
      </p>
    </div>
  );
}

export default Privacy;

import React from "react";

function Faq() {
  return (
    <div>
    <h2 className="privacyH2">AppNabil FAQ</h2>
      <h5 className="privacyH4">
        <b>Can I really make money doing this?</b>
      </h5>

      <p className="privacyP1">
        Yes, really. We will pay you for every unique install of the currently
        advertised app you make. You can make much more by using your account to
        get more users under your wings. We’ll pay you up to 50 % of every
        rewarded installation your friend in your network makes.
      </p>
      <h5 className="privacyH4">
        <b>How much money I can make?</b>
      </h5>

      <p className="privacyP1">
        If you want to make massive profits, it is important to create your
        network, using your sharelink. Your sharelink is the key to that money!
        It is a unique link to our webpage, which will put anyone who registers
        under your wings and they will become part of your network. Make sure to
        share this link with as many people as you can. You’ll get up to 50 % of
        every install these people make. The more people you have the more money
        you make!
      </p>
      <h5 className="privacyH4">
        <b>How do I start making money?</b>
      </h5>

      <p className="privacyP1">
        All it takes is to create your account with us and login with your
        device, which you will use to download the advertised apps.
      </p>
      <h5 className="privacyH4">
        <b>How do I make more money by sharing my sharelink?</b>
      </h5>

      <p className="privacyP1">
        Offers change every day, so make sure to come back often! That way
        you’ll not miss on new offers. You’ll make much more, if you invite
        friends to your network. If you get 10 friends and they download all the
        available offers, you’ll earn up to 50% of their profit. That up to 50%
        profit share is yours forever. Now, next week, next year, every year.
      </p>
      <h5 className="privacyH4">
        <b>When do I get reward for an install?</b>
      </h5>

      <p className="privacyP1">
        The reward should be credited after 30 seconds from starting the app
        (internet connection required). Number of installs can be checked in the
        account administration. Reward is not credited for invalid installs.
      </p>
      <h5 className="privacyH4">
        <b>
          I have downloaded the advertised app and didn’t get credited. What’s
          next?
        </b>
      </h5>
      <p className="privacyP1">
        This situation can happen if you have installed an app that you had
        already installed on your device in the past. Your installation was not
        unique, which is why you did not get rewarded for it. Another reason for
        not being credited is that the app is no longer advertised on our page
        or that the app was already sold out by the time of your download. If
        you are sure you should have been rewarded, please contact us.
      </p>
    </div>
  );
}

export default Faq;

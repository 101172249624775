
import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";

import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import { useForm } from "../../shared/hooks/form-hook";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_PASSWORDMATCH,
} from "../../shared/utils/validators";

import { useHttpClient } from "../../shared/hooks/http-hook";

import "./auth.css";
import ErrorDiv from "../../shared/components/UIElements/ErrorDiv";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useConfig } from "../../shared/hooks/config-hook";

function PasswordResetPage({passResetToken}) {
  const [getConfig]=useConfig();

  const history = useHistory();

  const [isResetPage, setResetPage] = useState(true);
  const [isFetchingDone,setFetchingDone] = useState(false);
  const { isLoading, error, sendRequest } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: isResetPage ? true: false,
      },
    },
    false
  );

  useEffect(()=>{
    async function verifyToken() {
        try {
            
            if(passResetToken) {
                await sendRequest(
                    `/users/auth/reset/${passResetToken}`,
                  );     
            }
            setFetchingDone(true);


          } catch(err) {
          }     
        
    }
    verifyToken();
    
  },[sendRequest,passResetToken,setFormData]);


  function switchModeHandler() {
    if (!isResetPage) {
      setFormData(
        {
          ...formState.inputs,
          newPassword: undefined,
          passwordConfirm: undefined,
        },
        formState.inputs.email.isValid 
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          newPassword: {
            value: "",
            isValid: false,
          },
          passwordConfirm: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setResetPage((prevMode) => !prevMode);
  }

  const authSubmitHandler = async event =>  {
    event.preventDefault();
    if (isResetPage) {
      try {
        await sendRequest(
          '/users/auth/recover',
          'POST',
          JSON.stringify({
            email: formState.inputs.email.value,
          }),
          {
            'Content-Type': 'application/json'
          }
        );
        
      } catch (err) {
      }
    } else {
      try {
        await sendRequest(
            `/users/auth/reset/${passResetToken}`,
            'POST',
            JSON.stringify({
              password: formState.inputs.newPassword.value,
              confirmPassword: formState.inputs.passwordConfirm.value
            }),
            {
              'Content-Type': 'application/json'
            }
          );
      } catch (err) {
          setTimeout(()=> {
            history.push('/',error);
          },3000);       
      }  
    }
  };  
  
  
  useEffect(()=> {    
    if(isFetchingDone && error === undefined) {
        passResetToken && switchModeHandler();      
    } else {
        passResetToken && switchModeHandler();
        
        error && setResetPage(true);       
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[error]);

   

 
    return (
        <div id="wrapper">
      <div className="container">
      {isLoading && <LoadingSpinner asOverlay />}
        <div className="phone-app-demo"></div>
        <div className="form-data">
          <form onSubmit={authSubmitHandler}>
            <div className="logo">
              <h1>{getConfig("identity","appName")}</h1>
            </div>
            {error && <ErrorDiv>{error}</ErrorDiv>}
            {isResetPage && <Input
              element="input"
              id="email"
              type="text"
              placeholder="Email"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email"
              onInput={inputHandler}
              
            />}
            {!isResetPage && <Input
              element="input"
              id="newPassword"
              type="password"
              placeholder="New Password"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid Password (at last 6 characters)"
              onInput={inputHandler}
            />}
            {!isResetPage && (
              <Input
                element="input"
                id="passwordConfirm"
                type="password"
                placeholder="Confirm Password"
                validators={[VALIDATOR_PASSWORDMATCH(formState.inputs.newPassword.value)]}
                errorText="Password mismatch"
                onInput={inputHandler}
              />
            )}
            <Button
              className="form-btn"
              type="submit"
              disabled={!formState.isValid}
            >
              {isResetPage ? "Reset My Password" : "Change Password"}
            </Button>
          </form>
        </div>
      </div>
    </div>
    );
}

export default PasswordResetPage;
import React from "react";
import Button from "../FormElements/Button";
import Modal from "./Modal";

import "./ModalRefer.css";

function ModalRefer(props) {
  return (
    <Modal
      show={props.show}
      onCancel={props.onCancel}
      id="modal-refer-id"
      className="modal-content"
      header={
        <div className="modal--refer-title">
          <h4 className="modal-title " id="exampleModalLongTitle">
            {props.title}
          </h4>
        </div>
      }
      bodyClass=""
      body={
        <React.Fragment>
          <div className="d-flex justify-content-around main-navigation modaldiv2">
            <div className="modal-points">
              <i className="fas fa-trophy modaldivi modal-refer-i" />{" "}
              {props.points}p
            </div>
          </div>
          <div className="modal-body modal--refer">{props.description}</div>
        </React.Fragment>
      }
      footerClass="footer-refer"
      footer={
        <React.Fragment>
          <form onSubmit={props.onSubmit}>
            <input
              type="text"
              defaultValue={props.referCode}
              onChange={props.onInput}
              className="input--form"
              placeholder="Invitation Code"
            />
            <Button
              id="code"
              className="btn btn-primary btn-refer"
              onClick={props.onCancel}
            >
              CONFIRM
            </Button>
          </form>
          <form onSubmit={props.onSubmitWithNoCode}>
            <Button
              id="noCode"
              className="btn btn-light btn-refer"
              onClick={props.onCancel}
            >
              NO CODE
            </Button>
          </form>
        </React.Fragment>
      }
    ></Modal>
  );
}

export default ModalRefer;
